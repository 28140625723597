import * as React from "react";
import * as ReactDOM from "react-dom";
import { Header, requestLogin } from "./Header";

ReactDOM.render(<Header />, document.getElementById("header"));

const login_button = document.getElementById("login_button");
if (login_button) {
  ReactDOM.render(
    <button role="button" onClick={requestLogin}>
      Login
    </button>,
    login_button
  );
}
